.App {
  font-family: "Montserrat","Inter" !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.demo-editor {
  height: 145px !important;
  background-color: white !important;
  padding: 0 10px;
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid #30bcc2 !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bhHnmT {
  margin: 0px !important;
}
.overlay::before,
.overlay::after {
  background: transparent !important;
}
a {
  border-bottom: unset;
  text-decoration: auto;
}
.blink_text {
  animation-name: blinker;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Prview::before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: transparent;
  box-shadow: inset 0px 0px 0px #6098ff;
  display: block;
  -webkit-transition: all 0.4s cubic-bezier(0.5, 0.24, 0, 1);
  transition: all 0.4s cubic-bezier(0.5, 0.24, 0, 1);
}
/* if you want it to show from the right use -300px instead */
.Prview:hover::before {
  box-shadow: inset 300px 0px 0px #6098ff;
}
