body {
  margin: 0;
  font-family: "Montserrat","Inter";
  background-color: #fafcff;
}

code {
  font-family: "Montserrat","Inter";
}
input:-webkit-autofill {
  background-color: #faffbd !important;
}
/* .MuiMenu-paper {
  min-height: 335px !important;
} */
.css-xvvqnf-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: #30bcc2 !important;
}

.rdw-editor-main {
  overflow: hidden !important;
}
.rdw-editor-wrapper {
  display: flex !important;
  flex-direction: column-reverse !important;
}
.MuiOtpInput-Box {
  gap: 6px !important;
}
.MuiOtpInput-Box input {
  width: 40px;
  height: 44px;
}
.MuiOtpInput-Box .MuiInputBase-sizeSmall {
  border-radius: 20px !important;
}
@media (min-width: 431px) and (max-width:715px) {
  .MuiOtpInput-Box input {
    height: 30px;
    width: 20px;
  }
  .MuiOtpInput-Box .MuiInputBase-sizeSmall {
    border-radius: 13px !important;
  }
}
@media (max-width: 430px) {
  .MuiOtpInput-Box input {
    height: 28px;
    width: 100%;
  }
  .MuiOtpInput-Box .MuiInputBase-sizeSmall {
    border-radius: 13px !important;
  }
}
.tooltip-content {
  width: 150px;
  height: 100px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
}
.tooltip-chip {
  background: rgba(218, 0, 55, 0.07);
  border: 1px solid #d10035;
  border-radius: 57px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #d10035;
  padding: 2px 8px;
  margin-right: 8px;
  width: 80px;
  text-align: center;
}
.styles-module_show__2NboJ {
  opacity: 1 !important;
}
.dapps-app-name {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.003em;
  color: #0f1f4a;
}
.dapps-subscriber {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.003em;
  color: #0f1f4a;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow {
  background: rgb(48 188 194) !important;
}

.MuiAutocomplete-popper .MuiAutocomplete-paper ::-webkit-scrollbar {
  width: 0.3em !important;
}

.MuiAutocomplete-popper .MuiAutocomplete-paper ::-webkit-scrollbar-track {
  background-color: #a0e5e8 !important;
}

.MuiAutocomplete-popper .MuiAutocomplete-paper ::-webkit-scrollbar-thumb {
  background-color: #30bcc2 !important;
  outline: 1px solid #a0e5e8 !important;
}
.MuiCheckbox-colorPrimary.Mui-checked {
  color: #30bcc2 !important;
}

.codeeditor ::-webkit-scrollbar {
  width: 0.3em !important;
  height: 7px !important;
}

.codeeditor ::-webkit-scrollbar-track {
  background-color: #a0e5e8 !important;
  border-radius: 25px !important;
}

.codeeditor ::-webkit-scrollbar-thumb {
  background-color: #30bcc2 !important;
  outline: 1px solid #a0e5e8 !important;
  border-radius: 25px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

/* ::-webkit-scrollbar {
  display: none;
} */
.scroller {
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
.card-slick .slick-list .slick-track {
  margin-top: 1.5rem;
  margin-left: 1rem;
}
.ribbon-edge {
  background: #53d1ef;
  position: relative;
  padding: 10px 20px 10px 20px;
  box-shadow: 8px 6px 10px #e7e7e7;
  width: 43%;
  right: -211px;
  text-align: 106px;
  top: 15px;
}
.ribbon-edge:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: -10px;
  right: 0;
  border: solid 10px transparent;
  border-top-width: 5px;
  border-bottom-width: 5px;
  border-bottom-color: #47b2cc;
  border-left-color: #47b2cc;
}
.demo-editor-desc {
  min-height: 145px !important;
  background-color: white !important;
  padding: 0 10px;
  border-top: 1px solid !important;
  border-left: 1px solid !important;
  border-right: 1px solid !important;
  border-radius: 14px 14px 0px 1px !important;
}
.demo-wrapper-dsc .rdw-editor-toolbar {
  border: unset !important;
  border-left: 1px solid !important;
  border-right: 1px solid !important;
  border-bottom: 1px solid !important;
  border-bottom-left-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
}
